import React from 'react';

import Layout from '@src/layouts';
import SEO from '@commons/SEO';

import { NewsletterForm } from '@pages-impl/newsletter';

import featuredImage from '@images/header-images/featured_image_og_codilime_newsletter.jpg';
import logo from '@images/codilime-logo/CodiLime_logo_white.svg';

import * as styles from './newsletter.module.scss';

export default function Newsletter(props) {
  return (
    <Layout
      contactFormType={false}
      {...props}
      classNames={{
        layoutWrapper: styles.layoutWrapper,
        footerClassNames: {
          footerWrapper: styles.footerWrapper,
        },
        fixedWrapper: styles.fixedWrapper,
      }}
      navbarProps={{
        logo,
        classNames: {
          mainNavigationContainer: styles.navigationWrapper,
          buttonInMenu: styles.buttonInMenu,
          menuBurger: styles.menuBurger,
        },
      }}
    >
      <SEO
        title="Newsletter - CodiLime"
        description="A newsletter of CodiLime articles, news and cool projects"
        featuredImage={featuredImage}
      />
      <h1 className={styles.title}>Networks’n’Cloud Insider</h1>
      <NewsletterForm />
    </Layout>
  );
}
