// extracted by mini-css-extract-plugin
export var backgroundGlow = "newsletter-form-module--background-glow--b0c4f";
export var bold = "newsletter-form-module--bold--29ddd";
export var description = "newsletter-form-module--description--87cb4";
export var descriptionParagraph = "newsletter-form-module--description-paragraph--2aa08";
export var elaboration = "newsletter-form-module--elaboration--02095";
export var envelope = "newsletter-form-module--envelope--cef01";
export var exploreBlog = "newsletter-form-module--explore-blog--e789f";
export var exploreBlogButton = "newsletter-form-module--explore-blog-button--4bd1c";
export var form = "newsletter-form-module--form--b079d";
export var formContainer = "newsletter-form-module--form-container--775b1";
export var guarantee = "newsletter-form-module--guarantee--93ea6";
export var hubspotTitle = "newsletter-form-module--hubspot-title--22dd2";
export var innerWrapper = "newsletter-form-module--inner-wrapper--ce0fd";
export var needsDescription = "newsletter-form-module--needs-description--3850a";
export var noSpam = "newsletter-form-module--no-spam--df06d";
export var outerBorder = "newsletter-form-module--outer-border--a2a7d";
export var outerWrapper = "newsletter-form-module--outer-wrapper--ab332";
export var thankYouContainer = "newsletter-form-module--thank-you-container--1f80a";
export var thankYouMessage = "newsletter-form-module--thank-you-message--ba3fc";
export var white = "newsletter-form-module--white--b95bc";